.signin-logo-stats {
  width: 10rem;
}

.table-container {
  margin-top: 1rem;
  font-size: 80%;
  text-align: center;
}

.ua-td {
  word-break: break-all;
}

.time-td {
  word-break: keep-all;
}

.date-td {
  word-break: keep-all;
}

.user-list-unique {
  margin-top: 1.5rem;
  font-family: "Noto Sans Arabic", sans-serif;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.details-icons {
  margin-right: 0.2rem;
}

.list-item-users {
  background-color: #f0ffc2;
}

.button-row {
  margin-bottom: 1rem;
}

.clear-btn {
  background-color: #ff6464;
  outline: none !important;
  border: none !important;
}

.refresh-btn {
  background-color: #91c483;
  outline: none !important;
  border: none !important;
}

.loading-row {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 6rem;
}
