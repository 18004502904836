.chat-box {
  margin-top: 4rem;
}

.bot-list {
  background-color: #e5e5e5;
  padding: 1rem;
  font-size: 1rem;
}

.user-list {
  padding: 1rem;
  font-size: 1;
}

.chat-container {
  width: 100% !important;
  margin-top: 3.5rem;
  font-family: "Noto Sans Arabic", sans-serif;
}
.chat-col {
  padding: 0px !important;
}

.prompt-warning {
  font-size: 1;
}

.form-row {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.nav-bottom {
  padding: 0px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.conversation-row {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.form-box:focus {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  outline: none !important;
  outline: 0px none #fff !important;
  border-color: none;
  border: none !important;
}

.form-box {
  height: 3.5rem;
  margin-top: 0.8rem;
  font-family: "Noto Sans Arabic", sans-serif;
}

.icon-col {
  display: flex;
  align-items: center;
}

.submit-icon {
  font-size: 1.8rem;
}

.hero-container {
  margin-top: 5rem;
}
.hero-row {
  margin-bottom: 2rem;
}

.hero-title {
  font-family: "Noto Sans Arabic", sans-serif;
  font-size: 2rem;
}

.sub-title {
  font-family: "Noto Sans Arabic", sans-serif;
  font-size: 1.3rem;
}
.subtitle-row {
  margin-top: 1rem;
  margin-bottom: 0.4rem;
}

.sample-icons {
  margin-right: 0.5rem;
}

.warning-row {
  font-family: "Noto Sans Arabic", sans-serif;
  font: 0.4rem;
  color: #d9d9d9 !important;
}

.alert-item {
  background-color: #d9d9d9 !important;
}

::placeholder {
  font-size: 1em;
  font-family: "Noto Sans Arabic", sans-serif;
}
.list-arrow {
  margin-right: 0.6rem;
}

.user-icon {
  width: 30px;
  margin-left: 2rem;
}

.text-section {
  align-content: right;
  text-align: right;
  color: black !important;
  font-size: 1rem !important;
}

.share-icon {
  margin-right: 0.5rem;
}

.refresh-icon {
  color: white;
  margin-right: 1rem;
  font-size: 2rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.top-div {
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
}

.chat-effect {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.spin-once {
  display: inline-block;

  border-radius: 50%;
  cursor: pointer;
  animation: spin 1s;
  will-change: transform;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
