.top-nav {
  height: 4rem;
  background-color: black;
}

.nav-txt {
  padding-top: 10rem !important;
  color: white;
  font-family: "Comfortaa", cursive;
  font-size: 1.3rem;
}

.nav-txt-ar {
  font-family: "Noto Sans Arabic", sans-serif;
  padding-top: 10rem !important;
  color: white;
  font-size: 1.3rem;
}
